@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.PreLoader {
  background: white;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 9999;
  position: fixed;
}
.PreLoader .loader {
  width: 64px;
  z-index: 99;
  height: 64px;
  position: relative;
  background-image: linear-gradient(#03aeaf 16px, transparent 0), linear-gradient(rgb(0, 0, 128) 16px, transparent 0), linear-gradient(rgb(0, 0, 128) 16px, transparent 0), linear-gradient(#03aeaf 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top, left bottom, right top, right bottom;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    width: 64px;
    height: 64px;
    transform: rotate(0deg);
  }
  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }
  100% {
    width: 64px;
    height: 64px;
    transform: rotate(360deg);
  }
}
.PreLoader .middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  position: absolute;
}

.PreLoader {
  display: none;
  /* Initially hide the preloader */
}

.PreLoader.visible {
  display: block;
  /* Show the preloader when the 'visible' class is present */
}

.PreLoader.hidden {
  display: none;
  /* Hide the preloader when the 'hidden' class is present */
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}