@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.Mission {
  background-color: #ebf8f8;
}
.Mission .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .Mission .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Mission .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .Mission .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .Mission .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .Mission .container {
    padding: 50px 14px;
  }
}
.Mission .container .row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px 20px;
}
.Mission .container .row .card {
  width: 350px;
  text-align: center;
  background-color: white;
  padding: 18px 16px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 19px -13px rgba(0, 0, 0, 0.75);
}
.Mission .container .row .card:hover::before {
  animation: slide-shine 1.2s ease-in-out;
}
.Mission .container .row .card svg, .Mission .container .row .card h3, .Mission .container .row .card p, .Mission .container .row .card img {
  transition: 0.2s all;
}
.Mission .container .row .card img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
}
.Mission .container .row .card h3 {
  margin: 10px 0;
  font-size: 24px;
  color: rgb(0, 0, 128);
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.Mission .container .row .card p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 6px 0;
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 15px !important;
}
@media all and (max-width: 992px) {
  .Mission .container .row .card p {
    font-size: 16px;
  }
}
.Mission .container .row .card::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
}
@keyframes slide-shine {
  0% {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.0200455182) 0%, rgba(255, 255, 255, 0) 11%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 86%, rgba(255, 255, 255, 0) 100%);
    transform: translate(-100%, -100%);
    opacity: 0.6s;
    /* Start the animation from the top left corner */
  }
  100% {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.0200455182) 0%, rgba(255, 255, 255, 0) 11%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 86%, rgba(255, 255, 255, 0) 100%);
    transform: translate(100%, 100%);
    opacity: 1;
    /* Move the element to the bottom right corner */
  }
}