@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.ServiceDesc .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .ServiceDesc .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ServiceDesc .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .ServiceDesc .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .ServiceDesc .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .ServiceDesc .container {
    padding: 50px 14px;
  }
}
.ServiceDesc .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* .right-col animation styling */
}
@media all and (max-width: 768px) {
  .ServiceDesc .container .row {
    flex-direction: column;
  }
}
.ServiceDesc .container .row .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.ServiceDesc .container .row .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .ServiceDesc .container .row .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .ServiceDesc .container .row .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.ServiceDesc .container .row .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.ServiceDesc .container .row .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .ServiceDesc .container .row .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .ServiceDesc .container .row .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.ServiceDesc .container .row .left-col {
  width: 60%;
}
@media all and (max-width: 768px) {
  .ServiceDesc .container .row .left-col {
    width: 100%;
  }
}
.ServiceDesc .container .row .left-col h2 {
  font-size: 36px;
  padding-bottom: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  background: linear-gradient(90deg, rgb(0, 0, 128) 0%, rgb(0, 0, 128) 14%, #03aeaf 54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media all and (max-width: 1200px) {
  .ServiceDesc .container .row .left-col h2 {
    font-size: 32px;
  }
}
@media all and (max-width: 992px) {
  .ServiceDesc .container .row .left-col h2 {
    font-size: 30px;
  }
}
@media all and (max-width: 768px) {
  .ServiceDesc .container .row .left-col h2 {
    font-size: 28px;
  }
}
@media all and (max-width: 576px) {
  .ServiceDesc .container .row .left-col h2 {
    font-size: 24px;
  }
}
.ServiceDesc .container .row .left-col p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .ServiceDesc .container .row .left-col p {
    font-size: 16px;
  }
}
.ServiceDesc .container .row .right-col {
  margin-left: 50px;
}
@media all and (max-width: 768px) {
  .ServiceDesc .container .row .right-col {
    margin-left: 0;
    margin-top: 60px;
  }
}
.ServiceDesc .container .row .right-col img {
  width: 300px;
  height: 360px;
  object-fit: cover;
  -webkit-box-shadow: -2px 3px 39px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 3px 39px -16px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 3px 39px -16px rgba(0, 0, 0, 0.75);
  border-radius: 28px;
}