@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Montserrat:wght@400;500;600;700;800;900&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
.ServiceIntro {
  background-color: rgb(250, 251, 255);
}
.ServiceIntro .container {
  padding: 100px 100px;
}
@media all and (min-width: 2000px) {
  .ServiceIntro .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .ServiceIntro .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .ServiceIntro .container {
    padding: 80px 50px;
  }
}
@media all and (max-width: 768px) {
  .ServiceIntro .container {
    padding: 80px 32px;
  }
}
@media all and (max-width: 576px) {
  .ServiceIntro .container {
    padding: 50px 14px;
  }
}
.ServiceIntro .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* .right-col animation styling */
}
@media all and (max-width: 768px) {
  .ServiceIntro .container .row {
    flex-direction: column;
  }
}
.ServiceIntro .container .row .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.ServiceIntro .container .row .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .ServiceIntro .container .row .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .ServiceIntro .container .row .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.ServiceIntro .container .row .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.ServiceIntro .container .row .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .ServiceIntro .container .row .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .ServiceIntro .container .row .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.ServiceIntro .container .row .left-col {
  width: 50%;
}
@media all and (max-width: 768px) {
  .ServiceIntro .container .row .left-col {
    width: 100%;
    padding-bottom: 60px;
  }
}
.ServiceIntro .container .row .left-col .content h3 {
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #03aeaf;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  position: relative;
}
.ServiceIntro .container .row .left-col .content h3::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 24px;
  left: -16px;
  background-color: #03aeaf;
}
@media all and (max-width: 576px) {
  .ServiceIntro .container .row .left-col .content h3::before {
    left: -9px;
  }
}
.ServiceIntro .container .row .left-col .content h2 {
  font-size: 28px;
  background: linear-gradient(90deg, rgb(0, 0, 128) 0%, rgb(0, 0, 128) 14%, #03aeaf 54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.ServiceIntro .container .row .left-col .content p {
  font-size: 17px;
  line-height: 156%;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}
@media all and (max-width: 992px) {
  .ServiceIntro .container .row .left-col .content p {
    font-size: 16px;
  }
}
.ServiceIntro .container .row .left-col .content a {
  display: inline-block;
  padding: 13px 16px;
  margin-top: 20px;
  text-decoration: none;
  font-size: 20px;
  border-radius: 24px;
  transition: all 0.3s;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  background-color: rgb(0, 0, 128);
  color: rgb(250, 251, 255);
}
.ServiceIntro .container .row .left-col .content a:hover {
  transform: translateY(-2px);
  -webkit-box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 17px 39px -20px rgba(0, 0, 0, 0.75);
}
.ServiceIntro .container .row .right-col {
  width: 50%;
  padding-left: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
}
@media all and (max-width: 768px) {
  .ServiceIntro .container .row .right-col {
    width: 100%;
    padding-left: 0;
  }
}
.ServiceIntro .container .row .right-col .img-container {
  position: relative;
  transition: 0.8s all;
}
.ServiceIntro .container .row .right-col .img-container img {
  width: 100%;
  height: 180px;
  max-width: 100%;
  object-fit: cover;
}
.ServiceIntro .container .row .right-col .img-container:hover::before {
  transform: scaleY(1);
  opacity: 1;
}
.ServiceIntro .container .row .right-col .img-container:hover::after {
  opacity: 1;
}
.ServiceIntro .container .row .right-col .img-container::before {
  position: absolute;
  content: "";
  inset: 0;
  height: 99%;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: middle;
  background: linear-gradient(90deg, rgba(0, 0, 128, 0.4) 0%, rgba(3, 174, 175, 0.4) 56%, rgba(3, 174, 175, 0.4) 100%);
  backdrop-filter: blur(2px);
  transition: 0.4s all;
}
.ServiceIntro .container .row .right-col .img-container::after {
  position: absolute;
  opacity: 0;
  transform: translateY(-15px);
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: white;
  transition: 0.4s all;
  padding: 0 10px;
}
.ServiceIntro .container .row .right-col .img-container.first::after {
  content: "Suction clearance";
}
.ServiceIntro .container .row .right-col .img-container.second::after {
  content: "Ear lobule repair";
}
.ServiceIntro .container .row .right-col .img-container.third::after {
  content: "Wax removal";
}
.ServiceIntro .container .row .right-col .img-container.fourth::after {
  content: "Septoplasty";
}